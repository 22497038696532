<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="card-shadow border-radius-md v-card theme--light mr-2">
          <div>
            <br />
            <h2 class="mt-n4 ml-2">Upload CSV file with Actual Historical Monthly kWh for each site:</h2>
          </div>
          <v-row class="d-flex" style="width: 800px">
            <v-col class="d-flex">
              <v-btn
                :ripple="false"
                :elevation="0"
                class="font-weight-bold text-xs btn-default bg-gradient-default mt-4 ml-2"
                @click="startUploadMonthlyKwh"
              >
                Upload CSV
              </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="showUploadKwhModal" width="500">
            <v-card>
              <v-spacer>
                <v-alert
                  type="success"
                  dismissible
                  v-model="showUploadKwhSuccessAlert"
                >
                  Site data was successfully uploaded.
                </v-alert>
                <v-alert type="error" dismissible v-model="showUploadKwhErrorAlert">
                  Error: {{ this.uploadKwhError }}
                </v-alert>
              </v-spacer>
              <v-card-title>
                Upload kWh csv
              </v-card-title>
              <v-card-text>
                <v-file-input
                  v-model="uploadKwhData"
                  accept="text/csv"
                  label="CSV file"
                  required
                ></v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" outlined @click="closeUploadKwh">
                  Cancel
                </v-btn>
                <v-btn class="font-weight-bold text-xs btn-success bg-gradient-success" @click="submitUploadKwh">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <!-- future modal -->
    <v-row>
      <v-col cols="12">
        <div class="card-shadow border-radius-md v-card theme--light mr-2">
          <div>
            <br />
            <h2 class="mt-n4 ml-2">Upload CSV with Projected Monthly kWh for each site:</h2>
          </div>
          <v-row class="d-flex" style="width: 800px">
            <v-col class="d-flex">
              <v-btn
                :ripple="false"
                :elevation="0"
                class="font-weight-bold text-xs btn-default bg-gradient-default mt-4 ml-2"
                @click="startFutureMonthlyKwh"
              >
                Upload CSV
              </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="showUploadFutureModal" width="500">
            <v-card>
              <v-spacer>
                <v-alert
                  type="success"
                  dismissible
                  v-model="showUploadFutureSuccessAlert"
                >
                  Site data was successfully uploaded.
                </v-alert>
                <v-alert type="error" dismissible v-model="showUploadFutureErrorAlert">
                  Error: {{ this.uploadFutureError }}
                </v-alert>
              </v-spacer>
              <v-card-title>
                Upload Anticipated kWh csv
              </v-card-title>
              <v-card-text>
                <v-file-input
                  v-model="uploadFutureData"
                  accept="text/csv"
                  label="CSV file"
                  required
                ></v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" outlined @click="closeUploadFuture">
                  Cancel
                </v-btn>
                <v-btn class="font-weight-bold text-xs btn-success bg-gradient-success" @click="submitUploadFuture">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  data() {
    return {
      showUploadKwhSuccessAlert: false,
      showUploadFutureSuccessAlert: false,
      showUploadKwhErrorAlert: false,
      showUploadFutureErrorAlert: false,
      showUploadKwhModal: false,
      showUploadFutureModal: false,
      uploadKwhError: "",
      uploadFutureError: "",
      uploadKwhData: null,
      uploadFutureData: null,
    };
  },
  mounted() {
    document.title = "Admin"
  },
  methods: {
    startUploadMonthlyKwh() {
      this.showUploadKwhErrorAlert = false;
      this.showUploadKwhSuccessAlert = false;
      this.showUploadKwhModal = true;
    },
    startFutureMonthlyKwh() {
      this.showUploadFutureModal = true;
      this.showUploadFutureSuccessAlert = false;
    },
    handleSuccessfulKwhUpload(response) {
      if (response.status != 200) {
        this.handleErrorKwhUpload(this.createErrorMessage(withErrors));
      } else {
        this.showUploadKwhSuccessAlert = true;
      }
    },
    handleSuccessfulFutureUpload(response) {
      if (response.status != 200) {
        this.handleErrorFutureUpload(this.createErrorMessage(withErrors));
      } else {
        this.showUploadFutureSuccessAlert = true;
      }
    },
    handleErrorKwhUpload(error) {
      this.uploadKwhError = error;
      this.showUploadKwhErrorAlert = true;
    },
    handleErrorFutureUpload(error) {
      this.uploadFutureError = error;
      this.showUploadFutureErrorAlert = true;
    },
    closeUploadKwh() {
      this.showUploadKwhModal = false;
    },
    closeUploadFuture() {
      this.showUploadFutureModal = false;
    },
    submitUploadKwh() {
      const self = this;

      if (this.uploadKwhData) {
        Service.uploadKwhData(this.uploadKwhData)
          .then(this.handleSuccessfulKwhUpload)
          .catch((error) => {
            this.handleErrorKwhUpload(error.response.data.error);
          });
      } else {
        this.handleErrorKwhUpload("No file given");
      }
    },
    submitUploadFuture() {
      if (this.uploadFutureData) {
        Service.uploadProjectionData(this.uploadFutureData)
          .then(this.handleSuccessfulFutureUpload)
          .catch((error) => {
            this.handleErrorFutureUpload(error.response.data.error);
          });
      } else {
        this.handleErrorFutureUpload("No file given");
      }
    }
  },
};
</script>
